import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Header } from '../../components'
import BlueCheck from '../../data/blueCheck.png'
import DropDownArrow from '../../data/rightArrow.png'
import '../../pages/NewPages/Map/MapBox.css'
import { BASE_URL, get, patch, post } from '../../Services/Calls'
import { mapboxglAccessToken, notify } from '../../Services/Utils'
import './NewMain.css'

mapboxgl.accessToken = mapboxglAccessToken;

const CreatePOI = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [poiName, setPoiName] = useState('')
    const [description, setDescription] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [fromStatus, setFromStatus] = useState('')
    const [poiId, setPoiId] = useState('')

    const [type, setType] = useState(0);
    const [personalized, setPersonalized] = useState(true);
    const [noNavigatable, setNoNavigatable] = useState(false);

    const [showCountrySelect, setShowCountrySelect] = useState(false);

    const [poiLocation, setPoiLocation] = useState('');

    const [map, setMap] = useState(null);
    const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');
    const [mapMode, setMapMode] = useState('1');

    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const [friendlyId, setFriendlyId] = useState('');

    const [countryData, setCountryData] = useState([]);
    const [country, setCountry] = useState({});
    useEffect(() => {
        (async () => {
            await getCountries()
        })();
    }, [])

    const getCountries = async () => {
        try {
            const response = await get(BASE_URL + '/countries?order=ASC')
            if (response && response.length > 0) {
                setCountryData(response)
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    useEffect(() => {
        // Create a map instance
        mapRef.current = new mapboxgl.Map({
            container: 'map', // Specify the container ID
            style: mapStyle, // Specify the map style
            center: [32.00, 35.00], // Specify the starting center
            zoom: 8, // Specify the starting zoom
        });
        mapRef.current.addControl(new mapboxgl.NavigationControl());

        mapRef.current.on('load', () => {
            setMap(mapRef.current)
        })
        mapRef.current.on('style.load', function () {
            mapRef.current.on('click', function (e) {
                var coordinates = e.lngLat;
                new mapboxgl.Popup()
                if (markerRef.current != null) {
                    markerRef.current.remove()
                }
                markerRef.current = new mapboxgl.Marker()
                    .setLngLat(coordinates)
                    .addTo(mapRef.current);
                setPoiLocation(coordinates.lat + "," + coordinates.lng);
                mapRef.current.flyTo({ center: [coordinates.lng, coordinates.lat], zoom: 10 });
            })
        })
        // Clean up the map instance when the component unmounts
        return () => mapRef.current.remove()
    }, [mapStyle, mapMode]) // The empty dependency array ensures this useEffect runs only once

    useEffect(() => {
        if (location && location.state && location.state.from) {
            setFromStatus(location.state.from);
            if (location.state.data && location.state.data.id) {
                setPoiName(location.state.data.name);
                setDescription(location.state.data.description);
                setCountry(location.state.data.country);
                setPoiLocation(location.state.data.location.latitude + "," + location.state.data.location.longitude);
                setType(location.state.data.type);
                setPersonalized(location.state.data.personalized);
                setNoNavigatable(location.state.data.no_navigatable);
                setPoiId(location.state.data.id);
                setFriendlyId(location.state.data.friendly_id);

                markerRef.current = new mapboxgl.Marker()
                    .setLngLat([location.state.data.location.longitude, location.state.data.location.latitude])
                    .addTo(mapRef.current);
                mapRef.current.flyTo({ center: [location.state.data.location.longitude, location.state.data.location.latitude], zoom: 10 });
            }
        }
        window.scrollTo(0, 0);
    }, [])

    async function CreatePOIFun() {
        if (poiName == '' || poiName == undefined) {
            setErrorMsg('Please Enter POI Name');
            return;
        }
        if (description == '' || description == undefined) {
            setErrorMsg('Please Enter POI Description');
            return;
        }
        if (country && country.id) {

        }
        else {
            setErrorMsg('Please Select Country');
            return;
        }

        if (poiLocation == '' || poiLocation == undefined) {
            setErrorMsg('Please Select Latitude and Longitude');
            return;
        }

        let tempPoiLocation = poiLocation.split(',');

        try {
            let json = {
                name: poiName,
                description: description,
                location: {
                    latitude: tempPoiLocation[0],
                    longitude: tempPoiLocation[1]
                },
                country: country.id,
                type: type,
                gpx: "string",
                personalized: personalized,
                no_navigatable: noNavigatable
            };
            const response = await post(BASE_URL + '/pois', json);
            if (response.status == 200) {
                notify('POI created successfully');
                navigate('/poi');
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    async function editTripFun() {

        if (poiName == '' || poiName == undefined) {
            setErrorMsg('Please Enter POI Name.')
            return
        }
        if (description == '' || description == undefined) {
            setErrorMsg('Please Enter POI Description.')
            return
        }
        if (country && country.id) {

        }
        else {
            setErrorMsg('Please Select Country.')
            return
        }

        if (poiLocation == '' || poiLocation == undefined) {
            setErrorMsg('Please Select Latitude And Longitude.')
            return
        }

        let tempPoiLocation = poiLocation.split(',');

        try {
            let json = {
                name: poiName,
                description: description,
                location: {
                    latitude: tempPoiLocation[0],
                    longitude: tempPoiLocation[1]
                },
                country: country.id,
                type: type,
                gpx: "string",
                personalized: personalized,
                no_navigatable: noNavigatable
            }
            const response = await patch(BASE_URL + '/pois/' + poiId, json)
            if (response.status == 200) {
                notify('POI updated successfully')
                navigate('/poi')
            }
        }
        catch (ex) {
            alert(ex)
        }

        //     const response = await put(BASE_URL + '/trips/' + poiId, json)

        // } catch (ex) {
        //     alert(ex)
        // }
    }

    return (
        <div className='m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl'>
            <div className='flex justify-between items-center h-20'>
                <Header
                    category=''
                    title={fromStatus == 'create' ? 'Add New POI' : `Edit POI ${friendlyId}`}
                />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }} className="mb-10 mr-20">
                    {personalized ?
                        <img src={BlueCheck} style={{ height: 25, width: 25, cursor: 'pointer' }} onClick={() => { setPersonalized(!personalized) }} />
                        :
                        <div style={{ borderRadius: 4, height: 25, width: 25, border: '2px solid #2D96F3', cursor: 'pointer' }} onClick={() => { setPersonalized(!personalized) }} >
                        </div>
                    }
                    <div style={{ fontSize: 22, fontWeight: 'bold' }}>Personalized</div>
                </div>
            </div>
            <div className='countryFormMainDiv p-10'>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 100
                    }}
                >
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: 10,
                                width: '60%'
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>Name</span>
                            <input
                                type='text'
                                className='InputBoxCss'
                                style={{ width: 350 }}
                                value={poiName}
                                onChange={e => {
                                    setPoiName(e.target.value)
                                }}
                                onFocus={() => {
                                    setErrorMsg('')
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: 10,
                                marginTop: 40
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>Description</span>
                            <textarea
                                aria-invalid='false'
                                id='txtDescription'
                                rows='5'
                                class='MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline InputBoxCss'
                                style={{ width: 600 }}
                                value={description}
                                onChange={e => {
                                    setDescription(e.target.value)
                                }}
                                onFocus={() => setErrorMsg('')}
                            />
                        </div>
                    </div>

                </div>

                <div className='selectLatLongDiv' style={{ height: 'auto' }}>
                    <div>


                        <div
                            style={{
                                width: '60%'
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>
                                Country
                            </span>

                            <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowCountrySelect(!showCountrySelect); setErrorMsg("") }}>
                                {country && country.id ?
                                    <span style={{ color: '#000' }}>{country.name}</span>
                                    :
                                    <span style={{ color: 'gray' }}>Select Country</span>
                                }
                                <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showCountrySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
                            </div>

                            {showCountrySelect &&
                                <ClickAwayListener onClickAway={() => { setShowCountrySelect(false) }}>
                                    <div className="dropDownDiv">
                                        {countryData && countryData.length > 0 && countryData.map((item, index) => (
                                            <div className="countryDropDownItemDiv" onClick={() => { setCountry(item); setShowCountrySelect(false) }}>
                                                {item.name}
                                            </div>
                                        ))}
                                    </div>
                                </ClickAwayListener>
                            }
                        </div>
                    </div>

                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: 10,
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>Type</span>

                            <div className="selectPOITypeMainDiv">
                                <div>
                                    <div className="selectPOITypeMainIconDiv" onClick={() => { setType(1) }} >
                                        {type == 1 && (
                                            <div className="selectPOITypeMainIconSelectedDiv"></div>
                                        )}
                                    </div>
                                    <div>Point Of Intrest</div>
                                </div>
                                <div>
                                    <div className="selectPOITypeMainIconDiv" onClick={() => { setType(2) }} >
                                        {type == 2 && (
                                            <div className="selectPOITypeMainIconSelectedDiv"></div>
                                        )}
                                    </div>
                                    <div>Local Hero Tip</div>
                                </div>
                                <div>
                                    <div className="selectPOITypeMainIconDiv" onClick={() => { setType(3) }} >
                                        {type == 3 && (
                                            <div className="selectPOITypeMainIconSelectedDiv"></div>
                                        )}
                                    </div>
                                    <div>GPX</div>
                                </div>
                                <div>
                                    <div className="selectPOITypeMainIconDiv" onClick={() => { setType(4) }} >
                                        {type == 4 && (
                                            <div className="selectPOITypeMainIconSelectedDiv"></div>
                                        )}
                                    </div>
                                    <div>Package</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='selectLatLongDiv' style={{ alignItems: 'flex-start' }}>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: 10,
                                width: '60%'
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>
                                Latitude, Longitude
                            </span>
                            <input
                                type='text'
                                className='InputBoxCss'
                                style={{ width: 300 }}
                                placeholder='32.00, 34.00'
                                value={poiLocation}
                                onChange={e => {
                                    let latlng = e.target.value;
                                    if (e.target.value === '') {
                                        setPoiLocation('');
                                        if (markerRef.current != null) {
                                            markerRef.current.remove();
                                        }
                                    } else {
                                        setPoiLocation(latlng);
                                        if (markerRef.current != null) {
                                            markerRef.current.remove();
                                        }
                                    }
                                    latlng = latlng.trim();
                                    let originalLatLng = latlng;
                                    latlng = latlng.split(',');
                                    if (latlng.length === 2) {
                                        setPoiLocation(originalLatLng);
                                        markerRef.current = new mapboxgl.Marker()
                                            .setLngLat([latlng[1].trim(), latlng[0].trim()])
                                            .addTo(mapRef.current);
                                        map.flyTo({ center: [latlng[1].trim(), latlng[0].trim()], zoom: 10 });
                                    }
                                }}
                                onFocus={() => setErrorMsg('')}
                            />
                        </div>
                        <div className='outerMapContainer'>
                            <div id='map' style={{ width: '100%', height: '100%' }}></div>
                        </div>
                    </div>
                    {type == 2 &&
                        <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                            {noNavigatable ?
                                <img src={BlueCheck} style={{ height: 20, width: 20, cursor: 'pointer' }} onClick={() => { setNoNavigatable(!noNavigatable) }} />
                                :
                                <div style={{ borderRadius: 4, height: 20, width: 20, border: '2px solid #2D96F3', cursor: 'pointer' }} onClick={() => { setNoNavigatable(!noNavigatable) }} >
                                </div>
                            }
                            <div style={{ fontSize: 20, fontWeight: '600' }}>No Navigatable</div>
                        </div>
                    }
                </div>



                {errorMsg != '' && (
                    <div
                        style={{
                            textAlign: 'center',
                            color: 'red',
                            fontSize: 16,
                            marginTop: 30
                        }}
                    >
                        {errorMsg}
                    </div>
                )}
                {type == 5 ? (
                    <div className='flex justify-between items-center h-20' style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold'
                    }}>
                        <h1>This POI is of type attraction - please update the attraction to edit this POI</h1>
                    </div>) :
                    (fromStatus == 'create' ? (
                        <div
                            className='AddBtn'
                            onClick={() => {
                                CreatePOIFun()
                            }}
                        >
                            Add
                        </div>
                    ) : (
                        <div
                            className='AddBtn'
                            onClick={() => {
                                editTripFun()
                            }}
                        >
                            Update
                        </div>

                    )
                    )}
            </div>

        </div>
    )
}

export default CreatePOI
