import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";

import { userGrid } from "../data/dummy";
import { Header } from "../components";
import { BASE_URL, deleteCall, get } from "../Services/Calls";
import LeftArrow from "../data/leftArrow.png"
import RightArrow from "../data/rightArrow.png"
import BackWard from "../data/backWard.png"
import Forward from "../data/forward.png"

const Users = () => {
  const toolbarOptions = ['Delete', 'Search'];
  const editing = { allowDeleting: true, allowEditing: true };
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [userData, setUserData] = useState([]);
  const [allUserData, setAllUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10; // Number of records per page

  // Function to handle page change
  const onPageChange = (page) => {
    setCurrentPage(page);
    getUsers(page);
  };

  useEffect(() => {
    getUsers(currentPage);
  }, [currentPage])

  const getUsers = async (pageValue) => {
    try {
      const response = await get(BASE_URL + `/users?page=${pageValue}&take=${pageSize}&order=DESC`);
      const responseAllUsers = await get(BASE_URL + `/users?take=9999`);
      if (response && response.data && response.data.length > 0) {
        setUserData(response.data);
        setAllUserData(responseAllUsers.data);
        setTotalPages(Math.ceil(response.meta.itemCount / pageSize));
        setTotalItems(response.meta.itemCount);
      }
    }
    catch (ex) {
      alert(ex);
    }
  };

  const toolbarClick = args => {
    switch (args.item.text) {
      case 'Delete':
        args.cancel = true;
        setDeleteConfirm(true);
        break
      default:
        break
    }
  }

  let grid;

  const rowSelected = () => {
    if (grid) {
      const tempSelectedrecords = grid.getSelectedRecords();
      setSelectedRecords(tempSelectedrecords);
    }
  }

  const rowDeselected = args => {

    let tempSelectedRecords = [...selectedRecords]
    for (let i = 0; i < tempSelectedRecords.length; i++) {
      if (args.data.length > 1) {
        for (let j = 0; j < args.data.length; j++) {
          if (tempSelectedRecords[i].id === args.data[j].id) {
            tempSelectedRecords.splice(i, 1);
          }
        }
      } else {
        if (tempSelectedRecords[i].id === args.data.id) {
          tempSelectedRecords.splice(i, 1);
        }
      }
    }

    setSelectedRecords(tempSelectedRecords);
  }

  // New function to download CSV for users who want to be updated
  const downloadUpdatableUsersCsv = () => {
    // Filter users with keep_me_updated set to true
    const updatableUsers = allUserData.filter(user => user.keep_me_updated === true);

    // If no users to update, show alert
    if (updatableUsers.length === 0) {
      alert('No users with update preferences found.');
      return;
    }

    // Convert users to CSV
    const csvContent = [
      // CSV header (adjust columns as needed)
      ...updatableUsers.map(user =>
        [
          user.email,
        ].join(',')
      )
    ].join('\n');

    // Get current date in dd_mm_yyyy format
    const today = new Date();
    const dateString = `${today.getDate().toString().padStart(2, '0')}_${(today.getMonth() + 1).toString().padStart(2, '0')}_${today.getFullYear()}`;

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `indie4x4_users_keep_me_updated_${dateString}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDelete = async () => {
    try {
      for (let i = 0; i < selectedRecords.length; i++) {
        const response = deleteCall(
          await BASE_URL + '/users/' + selectedRecords[i].id
        );

        if (response) {

        }
      }
      setTimeout(() => {
        setDeleteConfirm(false);
        getUsers();
      }, 200);

    } catch (ex) {
      alert(ex)
    }
  }

  const numbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className="m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl">
      <Header category="" title="Users" />
      <div className="mb-4">
        <button
          onClick={downloadUpdatableUsersCsv}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Download Users with "Keep Me Updated"
        </button>
      </div>
      {userData && userData.length > 0 &&
        <GridComponent
          dataSource={userData}
          enableHover={false}
          allowPaging={false}
          // allowFiltering
          // pageSettings={{ pageCount: 5,pageSize: 10 }}
          toolbar={toolbarOptions}
          editSettings={editing}
          allowSorting
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {userGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
        </GridComponent>
      }
      {deleteConfirm && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: 400,
              height: 200,
              background: '#fff',
              borderRadius: 10,
              padding: '15px 20px'
            }}
          >
            <div
              style={{
                fontSize: 24,
                fontWeight: 'bold',
                color: '#000',
                marginBottom: 15
              }}
            >
              Delete Alert
            </div>
            <div style={{ fontSize: 18, color: '#000', marginBottom: 10 }}>
              {selectedRecords.length > 0 ? "Are you sure you want to delete selected rows." : "Please select at-least one row."}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 20
              }}
            >
              <div
                className='confirmOkBtn'
                onClick={() => {
                  getUsers();
                  setDeleteConfirm(false);
                }}
              >
                {selectedRecords.length > 0 ? "Cancle" : "Ok"}
              </div>
              {selectedRecords.length > 0 &&
                <div
                  className='confirmOkBtn'
                  onClick={() => {
                    handleDelete()
                  }}
                >
                  Yes
                </div>
              }
            </div>
          </div>
        </div>
      )}
      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 10px 10px 27px', border: '1px solid #e0e0e0' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, fontSize: 13 }}>
          <div onClick={() => { if (currentPage > 1) { onPageChange(1) } }} style={{ marginRight: 21 }}>
            <img src={BackWard} style={{ width: 11, height: 11, opacity: currentPage > 1 ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div onClick={() => { if (currentPage > 1) { onPageChange(currentPage - 1) } }} style={{ marginRight: 14 }}>
            <img src={LeftArrow} style={{ width: 9, height: 9, opacity: currentPage > 1 ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, fontSize: 13 }}>
            {currentPage > 2 &&
              <div className="pagingNumber" onClick={() => { onPageChange(currentPage - 2) }} style={{ fontWeight: 'bold' }}>
                ...
              </div>
            }
            {numbers.map((number) => (
              <>
                {((number === currentPage || number === currentPage - 1 || number === currentPage + 1) || (currentPage === 1 && number === 3) || (currentPage === totalPages && number === totalPages - 2)) &&
                  <div key={number} className={currentPage === number ? "pagingNumberSelected" : "pagingNumber"} onClick={() => { onPageChange(number) }}>{number}</div>
                }
              </>
            ))}
            {currentPage + 1 < totalPages &&
              <div className="pagingNumber" onClick={() => { onPageChange(currentPage + 2) }} style={{ fontWeight: 'bold' }}>
                ...
              </div>
            }
          </div>
          <div onClick={() => { if (totalPages !== currentPage) { onPageChange(currentPage + 1) } }} style={{ marginLeft: 13.5 }}>
            <img src={RightArrow} style={{ width: 10, height: 10, opacity: totalPages !== currentPage ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div onClick={() => { if (totalPages !== currentPage) { onPageChange(totalPages) } }} style={{ marginLeft: 19 }}>
            <img src={Forward} style={{ width: 12, height: 12, opacity: totalPages !== currentPage ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
        </div>
        <span style={{ margin: '0 6.5px', fontSize: 13 }}>{`${currentPage} of ${totalPages} Pages (${totalItems} items)`}</span>
      </div>

    </div>
  );
};

export default Users;
